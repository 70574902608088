import React, { useEffect, useMemo, useState } from 'react';
import {
  formatCurrency,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
} from '../../utils';
import { Badge, Pagination, Table } from '../ui';
import { EditIcon, EyeIcon, SortIcon } from '../../icons';
import { RotasItem } from './project-details-rotas.component';

type ProjectPlacementsListProps = {
  isLoading: boolean;
  rotasItems: RotasItem[];
};

const itemsPerPage = 8;

export const ProjectPlacementsList = ({
  isLoading,
  rotasItems,
}: ProjectPlacementsListProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rotasList, setRotasList] = useState<RotasItem[]>([]);
  const [totalPages, setTotalPages] = useState(0);

  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const paginatedData = useMemo(() => {
    return getItemsOfPage(rotasList, currentPage, itemsPerPage);
  }, [rotasList, currentPage]);

  useEffect(() => {
    setRotasList(rotasItems);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(rotasItems.length, itemsPerPage));
  }, [rotasItems, sort]);

  return (
    <>
      <div className="data-table-container" data-testid="data-table-container">
        <Table>
          <thead>
            <tr>
              <th
                onClick={() => onSelectSort('name', setSort)}
                data-testid="name-column"
              >
                <div>
                  <label>Day</label>
                  <SortIcon value={sort.key === 'name' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('status', setSort)}
                data-testid="status-column"
              >
                <div>
                  <label>Activity Type</label>
                  <SortIcon value={sort.key === 'status' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('tag', setSort)}
                data-testid="tag-column"
              >
                <div>
                  <label>Role</label>
                  <SortIcon value={sort.key === 'tag' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('cpm', setSort)}
                data-testid="cpm-column"
              >
                <div>
                  <label>Status</label>
                  <SortIcon value={sort.key === 'cpm' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('type', setSort)}
                data-testid="type-column"
              >
                <div>
                  <label>Worker Name</label>
                  <SortIcon value={sort.key === 'type' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('region', setSort)}
                data-testid="region-column"
              >
                <div>
                  <label>Rate</label>
                  <SortIcon value={sort.key === 'region' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('trustHospital', setSort)}
                data-testid="trust-hospital-column"
              >
                <div>
                  <label>Accommodation</label>
                  <SortIcon
                    value={sort.key === 'trustHospital' ? sort.value : ''}
                  />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('specialty', setSort)}
                data-testid="specialty-column"
              >
                <div>
                  <label>Travel</label>
                  <SortIcon
                    value={sort.key === 'specialty' ? sort.value : ''}
                  />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Food</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Other Expenses</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Total Payroll Cost</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>
              <th
                onClick={() => onSelectSort('overview', setSort)}
                data-testid="overview-column"
              >
                <div>
                  <label>Total Worker Cost</label>
                  <SortIcon value={sort.key === 'overview' ? sort.value : ''} />
                </div>
              </th>
              <th data-testid="action-column">
                <div></div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td
                  colSpan={100}
                  className="text-center"
                  data-testid="loading-row"
                >
                  Loading ...
                </td>
              </tr>
            ) : (
              <>
                {paginatedData.length > 0 ? (
                  <>
                    {paginatedData.map((rotasItem) => {
                      return (
                        <tr
                          key={rotasItem._id}
                          data-testid={`placement-row-${rotasItem._id}`}
                        >
                          <td className="text-nowrap">
                            {rotasItem.day.toJSON().split('T')[0]}
                          </td>
                          <td>{rotasItem.activityType}</td>
                          <td>{rotasItem.role}</td>
                          <td>
                            <Badge
                              type={'success'}
                              data-testid={`type-badge-${rotasItem._id}`}
                            >
                              KPI Filled
                            </Badge>
                          </td>
                          <td>{rotasItem.workerName}</td>
                          <td>{formatCurrency(rotasItem.rate)}</td>
                          <td>{formatCurrency(0)}</td>
                          <td>{formatCurrency(0)}</td>
                          <td>{formatCurrency(0)}</td>
                          <td>{formatCurrency(0)}</td>
                          <td>{formatCurrency(rotasItem.rate)}</td>
                          <td>{formatCurrency(rotasItem.rate)}</td>
                          <td data-testid={`action-buttons-${rotasItem._id}`}>
                            <div className="action-item">
                              <div
                                className="view-icon disabled"
                                onClick={() => {}}
                                data-testid={`view-icon-${rotasItem._id}`}
                              >
                                <EyeIcon />
                              </div>
                              <div
                                className="edit-icon disabled"
                                onClick={() => {}}
                                data-testid={`edit-icon-${rotasItem._id}`}
                              >
                                <EditIcon />
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={100}
                      className="text-center"
                      data-testid="no-items-row"
                    >
                      No item found
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </Table>
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        itemsPerPage={itemsPerPage}
        totalEntries={rotasList.length}
        data-testid="pagination-component"
      />
    </>
  );
};
