import React, { useEffect, useState } from 'react';
import {
  DraggableArea,
  LeadCardPipeline,
  PipelineContainer,
  PipelineStatus,
} from '../ui';
import { leadStatusPipeline } from '../../constants';
import { ELeadStatus, Lead } from '../../backend/careo-api';
import { PlusIcon } from '../../icons';
import { useModal } from '../../contexts/side-modal.context';
import { UpdateLeadFinanCialForm } from './lead-form/update-lead-financial-form';
import { AxiosInstance } from '../../utils';

type LeadsPipelineProps = {
  leads: Lead[];
  getLeads: () => void;
  openViewModal: (data: Lead) => void;
};

export const LeadsPipeline = ({
  leads,
  getLeads,
  openViewModal,
}: LeadsPipelineProps) => {
  const [pipelines, setPipelines] = useState<PipelineStatus<Lead>[]>(
    leadStatusPipeline.map((el) => ({ ...el, items: [] })),
  );

  const { openModal, closeModal } = useModal();

  const openFinancialFormModal = (lead: Lead) => {
    openModal({
      title: '',
      component: (
        <UpdateLeadFinanCialForm
          step={-1}
          setStep={() => {}}
          createdLead={lead}
          setLead={() => {}}
          data-testid="financial-form-update"
          onSuccess={async () => {
            await AxiosInstance.leads.leadsControllerUpdateById(lead._id, {
              status: ELeadStatus.ProposalSent,
            });
            await getLeads();
          }}
        />
      ),
    });
  };

  const handleDrop = async (id: string, newListIndex: number) => {
    const newStatus = leadStatusPipeline[newListIndex].value;
    if (newStatus === ELeadStatus.ProposalSent)
      openFinancialFormModal(leads.find((i) => i._id === id)!);
    else
      await AxiosInstance.leads.leadsControllerUpdateById(id, {
        status: newStatus,
      });
  };

  useEffect(() => {
    const groupedLeads = leads.reduce<Record<string, Lead[]>>((acc, curr) => {
      const status = curr.status;
      acc[status] = acc[status] ? [...acc[status], curr] : [curr];
      return acc;
    }, {});

    console.log('groupedLeads');
    console.log(groupedLeads);

    setPipelines((prevPipelines) =>
      prevPipelines.map((pipeline) => ({
        ...pipeline,
        items: groupedLeads[pipeline.value] || [],
      })),
    );
  }, [leads]);

  return (
    <PipelineContainer>
      {pipelines.map((el, listIndex: number) => (
        <div className="item-container" key={listIndex}>
          <div className="item-header">
            <div className="item-title">
              <label>{el.label}</label>
              <div className="item-total">{el.items.length}</div>
            </div>
            <PlusIcon />
          </div>
          <div className="cards-list-container">
            {el.isDraggableTo ? (
              <DraggableArea
                pipeline={pipelines}
                listIndex={listIndex}
                itemIndex={0}
                onSuccess={getLeads}
                onDragRequest={handleDrop}
              />
            ) : (
              <div className="draggable-area false">
                <hr />
              </div>
            )}
            {el.items.map((item, itemIndex: number) => (
              <>
                <LeadCardPipeline
                  item={item}
                  listIndex={listIndex}
                  itemIndex={itemIndex}
                  openViewModal={openViewModal}
                />
                {el.isDraggableTo ? (
                  <DraggableArea
                    pipeline={pipelines}
                    listIndex={listIndex}
                    itemIndex={0}
                    onSuccess={getLeads}
                    onDragRequest={handleDrop}
                  />
                ) : (
                  <div className="draggable-area false">
                    <hr />
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      ))}
    </PipelineContainer>
  );
};
