import React, { useEffect, useState } from 'react';
import { EditIcon, LeftArrowIcon } from '../../icons';
import {
  ATag,
  CardContainer,
  TitlePage,
  ProjectDetailsContainer,
  TabPageContainer,
  ProjectDetailsGeneral,
  ProjectDetailsActivities,
  ProjectDetailsMatching,
  ProjectDetailsLegal,
  ProjectDetailsRotas,
  ProjectDetailsFinancial,
  ProjectForm,
} from '../../components';
import { AxiosInstance, AxiosInstanceErrorResponse, ERoute } from '../../utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Application,
  Candidate,
  Client,
  HostedFile,
  Project,
  Trust,
  User,
} from '../../backend/careo-api';
import { useAuth } from '../../contexts/auth.context';
import { toast } from 'react-toastify';
import { useModal } from '../../contexts/side-modal.context';

export enum EProjectTabs {
  ROTAS = 'rotas',
  GENERAL = 'general',
  ACTIVITIES = 'activities',
  MATCHING = 'matching',
  FINANCIAL = 'financial',
  LEGAL = 'legal',
}

const tabs = [
  EProjectTabs.ROTAS,
  EProjectTabs.GENERAL,
  EProjectTabs.ACTIVITIES,
  EProjectTabs.MATCHING,
  EProjectTabs.FINANCIAL,
  EProjectTabs.LEGAL,
];

export const ProjectDetailsPage = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal, closeModal } = useModal();

  const [selectedTab, setSelectedTab] = useState(EProjectTabs.GENERAL);
  const [project, setProject] = useState<Project | undefined>();
  const [documents, setDocuments] = useState<HostedFile[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [trustsList, setTrustsList] = useState<Trust[]>([]);
  const [clientsList, setClientsList] = useState<Client[]>([]);
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [placements, setPlacements] = useState<Application[]>([]);

  const getPlacements = async () => {
    await AxiosInstance.applications
      .applicationsControllerGetAllPlacements()
      .then((response) => {
        const result = response.data;
        setPlacements(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickGoBack = async () => {
    navigate(-1);
  };

  const openFormModal = (project?: Project) => {
    openModal({
      title: '',
      component: (
        <ProjectForm
          data-testid="create-new-project-form"
          usersList={usersList}
          trustsList={trustsList}
          clientsList={clientsList}
          selectedProject={project}
          getProjects={getProjectDetails}
        />
      ),
    });
  };

  const getProjectDetails = async () => {
    await AxiosInstance.projects
      .projectsControllerFindById(id!)
      .then((response) => {
        setProject(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCandidates = async () => {
    await AxiosInstance.candidates
      .candidatesControllerFindAll()
      .then((response) => {
        const result = response.data.items;
        setCandidates(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getProjectDocuments = async () => {
    await AxiosInstance.files
      .filesControllerFindProjectDocuments(id!)
      .then((result) => {
        setDocuments(result.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerFindAll()
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getTrusts = async () => {
    AxiosInstance.trust
      .trustsControllerFindAll()
      .then((response) => {
        setTrustsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getClients = async () => {
    AxiosInstance.clients
      .clientsControllerFindAll()
      .then((response) => {
        setClientsList(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    setSelectedTab(
      (location.pathname.split('/')?.[3] as EProjectTabs) ||
        EProjectTabs.GENERAL,
    );
  }, [location]);

  useEffect(() => {
    getProjectDetails();
    getProjectDocuments();
    getUsers();
    getClients();
    getTrusts();
    getCandidates();
    getPlacements();
  }, []);

  if (!project) {
    return <></>;
  }

  return (
    <>
      <ProjectDetailsContainer data-testid="project-details-container">
        <div className="project-header" data-testid="project-header">
          <div
            className="back-button"
            onClick={onClickGoBack}
            data-testid="back-button"
          >
            <LeftArrowIcon data-testid="left-arrow-icon" />
          </div>
          <TitlePage data-testid="title-page">Detail Project</TitlePage>
        </div>

        <CardContainer
          className="page-summary-container"
          data-testid="page-summary-container"
        >
          <div className="module-name" data-testid="module-name">
            <div className="module-info" data-testid="module-info">
              <div className="name" data-testid="project-name">
                {project.client.clientName}
              </div>
              <div className="availability" data-testid="project-full-name">
                {project.region}
              </div>
            </div>
          </div>

          <div
            className={`edit-container edit-icon`}
            onClick={() => openFormModal(project)}
            data-testid="edit-container"
          >
            <EditIcon data-testid="edit-icon" />
          </div>
        </CardContainer>

        <CardContainer
          className="project-information-container"
          data-testid="project-information-container"
        >
          <TabPageContainer data-testid="tab-page-container">
            <div className="tab-items-list" data-testid="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item text-capitalize ${el === selectedTab && 'active'}`}
                  onClick={() => navigate(`/${ERoute.PROJECTS}/${id}/${el}`)}
                  data-testid={`tab-item-${el.toLowerCase()}`}
                  key={el}
                >
                  {el}
                </ATag>
              ))}
            </div>
            <div
              className="tab-content-container"
              data-testid="tab-content-container"
            >
              {selectedTab === EProjectTabs.ROTAS && (
                <ProjectDetailsRotas
                  project={project}
                  candidates={candidates}
                  data-testid="project-rotas-tab"
                  placements={placements}
                  getPlacements={getPlacements}
                />
              )}
              {selectedTab === EProjectTabs.GENERAL && (
                <ProjectDetailsGeneral
                  project={project}
                  data-testid="project-general-tab"
                />
              )}
              {selectedTab === EProjectTabs.ACTIVITIES && (
                <ProjectDetailsActivities
                  project={project}
                  data-testid="project-activities-tab"
                />
              )}
              {selectedTab === EProjectTabs.MATCHING && (
                <ProjectDetailsMatching
                  project={project}
                  data-testid="project-matching-tab"
                />
              )}
              {selectedTab === EProjectTabs.FINANCIAL && (
                <ProjectDetailsFinancial
                  project={project}
                  data-testid="project-financial-tab"
                />
              )}
              {selectedTab === EProjectTabs.LEGAL && (
                <ProjectDetailsLegal
                  project={project}
                  documents={documents}
                  getDocuments={getProjectDocuments}
                  data-testid="project-legal-tab"
                />
              )}
            </div>
          </TabPageContainer>
        </CardContainer>
      </ProjectDetailsContainer>
    </>
  );
};
