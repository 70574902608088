import * as yup from 'yup';

export const PlacementCreateByApplicationIdSchema = yup.object().shape({
  applicationId: yup.string().required('Application is required'),
});

export const PlacementValidateSchema = yup.object().shape({
  fee: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Fee must be a valid number.')
    .required('Fee is required.')
    .positive('Fee must be a positive number'),

  vat: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('VAT must be a valid number.')
    .required('VAT is required.')
    .min(0, 'VAT must be 0 or a positive number.'),

  onCallFee: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('On Call Charge must be a valid number.')
    .required('On Call Charge is required.')
    .positive('On Call Charge must be a positive number')
    .test(
      'is-greater-than',
      'On Call Charge must be greater than On Call Rate',
      function (value) {
        const onCallRate = this.parent.onCallRate;
        return value > onCallRate;
      },
    ),

  onCallRate: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('On Call Rate must be a valid number.')
    .required('On Call Rate is required.')
    .positive('On Call Rate must be a positive number'),
});

export const AssignCandidateSchema = yup.object().shape({
  candidateId: yup.string().required('Candidate is required'),
});
