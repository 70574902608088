import React from 'react';
import styled from 'styled-components';
import { PlusIcon } from '../../../icons';
import { useModal } from '../../../contexts/side-modal.context';
import { FillPlacement } from './fill-placement-form';
import { Activity, Candidate, Job, Project } from '../../../backend/careo-api';

export const EmptyRotasCardContainer = styled.div`
  border-radius: 4px;
  height: 100%;
  padding-left: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    path {
      fill: #111827;
    }
  }

  &:hover {
    background-color: rgba(32, 156, 146, 0.05);
  }
`;

type EmptyRotasCardProps = {
  date: Date;
  activity: Activity;
  role: Job;
  project: Project;
  roleIndex: number;
  activityIndex: number;
  candidates: Candidate[];
  getPlacements: () => void;
};

export const EmptyRotasCard = ({
  date,
  activity,
  role,
  project,
  candidates,
  roleIndex,
  activityIndex,
  getPlacements,
}: EmptyRotasCardProps) => {
  const { openModal, closeModal } = useModal();
  const onClick = () => {
    openModal({
      title: 'Fill Placement',
      component: (
        <FillPlacement
          onCancel={() => {
            closeModal();
          }}
          onSuccess={() => {
            getPlacements();
            closeModal();
          }}
          date={date}
          activity={activity}
          role={role}
          project={project}
          roleIndex={roleIndex}
          activityIndex={activityIndex}
          candidates={candidates}
        />
      ),
    });
  };

  return (
    <EmptyRotasCardContainer onClick={onClick}>
      <PlusIcon data-testid="dots-icon" />
    </EmptyRotasCardContainer>
  );
};
