import React, { useEffect, useMemo, useState } from 'react';
import { Client, Project, Trust, User } from '../../../backend/careo-api';
import { GeneralFormStep } from './general-form-step';
import { ProjectFinancialFormStep } from './financial-form-step';
import { ActivitiesFormStep } from './activities-form-step';
import { LegalFormStep } from './legal-form-step';
import {
  ATag,
  FormContainer,
  ProgressBar,
  TabPageContainer,
  TitlePage,
} from '../../ui';

const requiredFields: Array<keyof Project> = [
  '_id',
  'cpms',
  'region',
  'trust',
  'client',
  'revenue',
  'cost',
  'margin',
  'revenuePer',
  'legalContact',
];

type ProjectFormProps = {
  selectedProject?: Project;
  usersList: User[];
  trustsList: Trust[];
  clientsList: Client[];
  getProjects: () => void;
};

export const calculateProjectProgress = (project: Project) => {
  // Count filled fields
  const filledFields = requiredFields.filter(
    (field) => project[field] !== undefined && project[field] !== null,
  ).length;

  // Calculate progress as a percentage
  return (filledFields / requiredFields.length) * 100;
};

export const ProjectForm = ({
  selectedProject,
  usersList,
  trustsList,
  clientsList,
  getProjects,
}: ProjectFormProps) => {
  const [step, setStep] = useState(1);
  const [createdProject, setCreatedProject] = useState<Project>();

  const calculateProgress = useMemo(() => {
    if (!createdProject) return 0;
    return calculateProjectProgress(createdProject);
  }, [createdProject]);

  useEffect(() => {
    if (selectedProject) {
      setCreatedProject({ ...selectedProject });
    }
  }, [selectedProject]);

  return (
    <FormContainer data-testid="form-container">
      <div className="side-modal-header-container">
        <TitlePage className="side-modal-title">
          {!selectedProject ? 'New Project' : 'Update Project'}
        </TitlePage>
      </div>
      <ProgressBar value={calculateProgress} data-testid="progress-bar" />
      <TabPageContainer
        className="form-tab-container"
        data-testid="tab-page-container"
      >
        <div className="tab-items-list" data-testid="tab-items-list">
          <ATag
            className={`tab-item ${step === 1 ? 'active' : ''}`}
            data-testid="tab-general"
          >
            General
          </ATag>
          <ATag
            className={`tab-item ${step === 2 ? 'active' : ''}`}
            data-testid="tab-activities"
          >
            Activities
          </ATag>
          <ATag
            className={`tab-item ${step === 3 ? 'active' : ''}`}
            data-testid="tab-financial"
          >
            Financial
          </ATag>
          <ATag
            className={`tab-item ${step === 4 ? 'active' : ''}`}
            data-testid="tab-legal"
          >
            Legal
          </ATag>
        </div>
      </TabPageContainer>

      {/* Render the form fields based on the current step */}
      {step === 1 && (
        <GeneralFormStep
          usersList={usersList}
          trustsList={trustsList}
          clientsList={clientsList}
          step={step}
          setStep={setStep}
          createdProject={createdProject}
          setCreatedProject={setCreatedProject}
          data-testid="general-form-step"
          getProjects={getProjects}
        />
      )}

      {step === 2 && createdProject && (
        <ActivitiesFormStep
          step={step}
          setStep={setStep}
          createdProject={createdProject}
          setCreatedProject={setCreatedProject}
          data-testid="financial-form-step"
          getProjects={getProjects}
        />
      )}

      {step === 3 && createdProject && (
        <ProjectFinancialFormStep
          step={step}
          setStep={setStep}
          createdProject={createdProject}
          setCreatedProject={setCreatedProject}
          data-testid="financial-form-step"
          getProjects={getProjects}
          usersList={usersList}
        />
      )}

      {step === 4 && createdProject && (
        <LegalFormStep
          usersList={usersList}
          step={step}
          setStep={setStep}
          createdProject={createdProject}
          setCreatedProject={setCreatedProject}
          data-testid="legal-form-step"
          getProjects={getProjects}
        />
      )}
    </FormContainer>
  );
};
