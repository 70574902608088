import React from 'react';
import styled from 'styled-components';
import { Badge } from '../../ui';
import { Application } from '../../../backend/careo-api';
import { DotsIcon } from '../../../icons';
import { Menu, MenuItem } from '@mui/material';

export const RotasCardContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #337e89;
  background: rgba(51, 126, 137, 0.05);
  padding: 8px;
  font-size: 12px;
  font-weight: 400;

  .top-container {
    display: flex;
    justify-content: space-between;
    .left-container {
      .name {
        font-weight: 400;
      }
      .time {
        color: #747474;
      }
    }
    .right-container {
      display: flex;
      gap: 5px;
      cursor: pointer;
      svg {
        path {
          fill: #7a7c7e;
        }
      }
    }
  }
  .compliance-container {
    color: #747474;
    font-size: 10px;
  }
`;

type RotasCardProps = {
  role: Application;
};

export const RotasCard = ({ role }: RotasCardProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
  };

  return (
    <RotasCardContainer>
      <div className="top-container">
        <div className="left-container" data-testid="left-container">
          <div className="name" data-testid="rota-name">
            {role.candidate?.firstName} {role.candidate?.lastName}
          </div>
          <div className="time" data-testid="rota-time">
            8:00 - 16:00
          </div>
        </div>
        <div className="right-container" data-testid="right-container">
          <Badge type="success" data-testid="badge-complete">
            {role.approvalStatus}
          </Badge>
          <DotsIcon data-testid="dots-icon" onClick={handleClick} />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            data-testid="dropdown-menu"
          >
            <MenuItem
              onClick={() => handleEdit()}
              data-testid={`dropdown-menu-item-edit`}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => handleClose()}
              data-testid={`dropdown-menu-item-cancel`}
            >
              Cancel
            </MenuItem>
          </Menu>
        </div>
      </div>
      {/* <div className="compliance-container">
        <div className="title-compliance">Compliance</div>
        <ProgressBar value={50} />
      </div> */}
    </RotasCardContainer>
  );
};
