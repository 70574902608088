import React from 'react';
import styled from 'styled-components';
// Assuming similar user icon component for lead owners
// Replace with actual icons used in your project
// Assuming a similar utility function for formatting dates
import { Abbreviation, Badge } from '../badge';
import { breakpoint } from '../../../constants';
import { handleDragStart } from './pipeline';
import { ELeadStatus, Lead } from '../../../backend/careo-api';
import { leadTagValueType } from '../../lead/view-lead.component';
import { Button } from '../buttons';
import { PlusIcon } from '../../../icons';
import { AxiosInstance, ERoute } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// Styled Components for the LeadCardPipeline
const LeadCardPipelineContainer = styled.div`
  background-color: #ffffff;
  padding: 16px;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;
  .top-container {
    .date-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .date {
      font-size: 14px;
      font-weight: 600;
    }
    .owner-icons {
      display: flex;
      gap: 1px;
    }

    .region {
      font-size: 12px;
      color: #a0aec0;
    }
  }

  .status-badges {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  .overview {
    font-size: 12px;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    padding: 5px;
    .top-container {
      .date-container {
      }
      .date {
        font-size: 8px;
      }

      .region {
        font-size: 8px;
      }
    }

    .status-badges {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }

    .overview {
      font-size: 8px;
    }
  }
`;

type LeadCardPipelineProps = {
  item: Lead;
  listIndex: number;
  itemIndex: number;
  openViewModal: (data: Lead) => void;
};

export const LeadCardPipeline = ({
  item,
  listIndex,
  itemIndex,
  openViewModal,
}: LeadCardPipelineProps) => {
  const profit = (item.revenue ?? 0) - (item.cost ?? 0) - (item.margin ?? 0);
  const navigate = useNavigate();

  const createProject = async (lead: Lead) => {
    console.log({ lead });
    await AxiosInstance.leads
      .leadsControllerCreateProjectViaLead({ leadId: lead._id })
      .then((result) => {
        const project = result.data;

        navigate(`/${ERoute.PROJECTS}/${project._id}`);
      })
      .catch((e) => {
        toast.error(
          'Could not create a project for this lead, please try again or contact Admin',
        );
      });
  };
  return (
    <LeadCardPipelineContainer
      key={item._id}
      draggable="true"
      onDragStart={(e) => handleDragStart(e, listIndex, itemIndex)}
      onDoubleClick={() => openViewModal(item)}
    >
      <div className="top-container">
        <div className="date-container">
          <div className="date">{item.hospital.clientName}</div>
          <div className="owner-icons">
            {item.cpms.slice(0, 1).map((el) => (
              <Abbreviation title={`${el.firstName} ${el.lastName}`}>
                {el.firstName[0]}
                {el.lastName[0]}
              </Abbreviation>
            ))}
            {item.cpms.length > 1 && (
              <Abbreviation>+{item.cpms.length - 1}</Abbreviation>
            )}
          </div>
        </div>
        <div className="region" data-testid="region">
          {item.specialty}
        </div>
      </div>
      <div className="status-badges">
        <Badge data-testid="lead-tag" type={leadTagValueType(item.tag)}>
          {item.tag}
        </Badge>
      </div>
      <div className="overview" data-testid="overview">
        {[ELeadStatus.NewLead, ELeadStatus.ScopingPhase].includes(
          item.status,
        ) ? (
          item.overview
        ) : (
          <>
            <div>
              <div>
                Revenue :<span> £ {item.revenue}</span>
              </div>
              <div>
                Cost :<span> £ {item.cost}</span>
              </div>
              <div>
                Margin :<span> £ {item.margin}</span>
              </div>
              <div>
                Profit :<span> £ {profit}</span>
              </div>
            </div>
          </>
        )}
      </div>

      <div
        style={{
          display: 'flex',
        }}
      >
        {item.project ? (
          <Button
            className="fit-width"
            type="neutral"
            variant="contained"
            onClick={() =>
              navigate(
                `/${ERoute.PROJECTS}/${item.project?._id ?? item.project}`,
              )
            }
          >
            View Project
          </Button>
        ) : (
          <Button
            className="fit-width"
            type="success"
            onClick={() => createProject(item)}
          >
            <PlusIcon /> Create Project
          </Button>
        )}
      </div>
      <div className="status-badges" data-testid="additional-status-badges">
        {item.trust && (
          <Badge type="neutral" data-testid="trust-hospital">
            {item.trust.name}
          </Badge>
        )}
        <Badge type="neutral" data-testid="specialty">
          {item.region}
        </Badge>
      </div>
    </LeadCardPipelineContainer>
  );
};
